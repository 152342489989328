<template>
    <li v-if='this.menu' class="dropdown-t">
        <span ref="ddt" class="navbarDropdown" :class="{'link-active': this.active, bg: this.menu.type == 'burger'}" v-on:mouseover="this.open">
            <span v-if="this.menu.type == 'left'"><i class='item-i' :class="{'arrow-down-right-active': this.active, 'arrow-down-right': !this.active}"></i>{{this.menu.content[this.$i18n.locale] ? this.menu.content[this.$i18n.locale] : this.menu.content[this.lang]}}</span>
            <span v-else-if="this.menu.type == 'right'">{{this.menu.content[this.$i18n.locale] ? this.menu.content[this.$i18n.locale] : this.menu.content[this.lang]}}<i class='item-i' :class="{'arrow-up-right-active': this.active, 'arrow-up-right': !this.active}"></i></span>
            <span v-else-if="this.menu.type == 'user'">
                <img v-if="this.$store.state.userData.avatar" class='user_img' :src="this.$store.state.userData.avatar" />
                <img v-else class='user_img' src="@/assets/user.svg"/>
                <span v-if="this.$store.getters.get_user_name != ''" class="user_name">
                    {{this.$store.getters.get_user_name}}
                </span>
            </span>
            <span v-else-if="this.menu.type == 'burger'"><i class='item-i' :class="{'burger-active': this.active, 'burger': !this.active}"></i></span>
            <router-link :to="this.menu.to" v-else-if="this.menu.type == 'center'"><p class='navbarDropdown'>{{this.menu.content[this.$i18n.locale] ? this.menu.content[this.$i18n.locale] : this.menu.content[this.lang]}}</p></router-link>
        </span>
        <transition @enter="enter" @leave="leave">
        <div ref="ddm" class="container content dropdown-m p-5" v-show="this.active && this.menu.first && this.menu.middle && this.menu.last" 
        :class="{left: this.menu.type == 'left' || this.menu.type == 'center', right: this.menu.type == 'right' || this.menu.type == 'user' || this.menu.type == 'burger'}">
            <MenuList :menu='this.menu' @close='this.close'/>
        </div>
        </transition>
    </li>
</template>

<script>
import { gsap } from "gsap";
import MenuList from '@/components/service/topMenu/MenuList'

export default {
    components: {
        MenuList
    },
    props: {
        menu: [Object, Boolean],
    },
    data() {
        return {
            active: false,
            lang: process.env.VUE_APP_I18N_LOCALE,
        }
    },
    mounted() {
        // console.log(process.env.VUE_APP_I18N_LOCALE)
    },
    methods: {
        open() {
            if (this.menu.first && this.menu.middle && this.menu.last) {
                this.active = true;
                this.$store.commit('DARK_WRAPPER', true);
                document.querySelector('body').addEventListener('mouseover', this.close);
            }
        },
        close(e) {
            if (!this.$el.contains(e.target) || e.type == 'click') {
                this.active = false;
                this.$store.commit('DARK_WRAPPER', false);
                document.querySelector('body').removeEventListener('mouseover', this.close);
            }
        },
        enter(el) {
            gsap.fromTo(el, {opacity: 0.1, height: '95%'}, {opacity: 1, height: 'auto', duration: 0.1});
        },
        leave(el, done) {
            gsap.fromTo(el, {opacity: 1}, {opacity: 0.1, duration: 0.2, onComplete: done});
        },
        logout() {
            this.$store.dispatch('logout', this.$router);
        },
    },
}
</script>

<style lang="scss" scoped>

.dropdown-t {
    height: 100%;
    .navbarDropdown {
        span {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            cursor: pointer;
        }
        .user_img {
            width: 42px;
            height: 42px;
            // margin-right: 0.5rem;
            border-radius: 50%;
        }
        .user_name {
            margin-left: 0.5rem;
        }
        .router-link-active {
            h4 {
                color: $color-menu-active;
            }
        }
        h4 {
            color: white;
        }
    }
    .dropdown-m {
        position: absolute;
        max-width: max-content;
        max-width: 80%;
        z-index: 3000;
        box-shadow: 2px 2px 4px 2px rgba(1, 52, 23, 0.1);
        border-radius: 0px 0px 6px 6px;
    }
    .navbarDropdown {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 4px solid transparent;
        box-sizing: padding-box;
        margin: 0 1.4rem;
        color: #FFF;
    }
}
.left {
    left: 0;
}
.right {
    right: 0;
}
.link-active {
    border-bottom: 4px solid $color-menu-active !important;
    color: $color-menu-active !important;
}
.bg {
    margin-right: 0 !important;
}

</style>