<template>
    <div v-if="this.menu.first || this.menu.middle || this.menu.last" class="row align-items-stretch">
        <h4 v-if="this.type == 'mobile' && this.menu.type != 'user'" v-on:click="this.close" class='light color-acent m-title' v-html="this.menu.content[this.$i18n.locale] ? this.menu.content[this.$i18n.locale] : this.menu.content[this.lang]"></h4>
        <div v-else-if="this.type == 'mobile' && this.menu.type == 'user'">
            <span class='col d-flex flex-row align-items-center m-title'>
                <img v-if="this.$store.state.userData.avatar" class='user_img' :src="this.$store.state.userData.avatar" />
                <img v-else class='user_img' src="@/assets/user.svg"/>
                
                <h4 class='light color-acent mb-0'>{{this.$store.getters.get_user_name}}</h4>
            </span>
        </div>
        <div class='col d-flex flex-column menu_col'>
            <router-link v-for="(link, index) in this.menu.first" :id="link.to" v-show="(this.type == 'mobile' && link.mobile_visible !== false) || this.type != 'mobile' && link.desktop_visible !== false"  :class='{"not": link.to == "#", "menu-header": link.type == "title"}, link.type' :key='index' :to="link.to">
                <div class="c34 d-flex align-items-center justify-content-center mr-05"><i class='item-i contain' :class='link.icon'></i></div>
                <h4 v-if="link.type == 'title'" v-on:click="(e) => {this.close(e, link)}" :class="{'m-h':this.type == 'mobile'}" v-html="link.content[this.$i18n.locale] ? link.content[this.$i18n.locale] : link.content[this.lang]"></h4>
                <span v-if="link.type == 'item'" v-on:click="(e) => {this.close(e, link)}" :class="{'m-s':this.type == 'mobile'}" v-html="link.content[this.$i18n.locale] ? link.content[this.$i18n.locale] : link.content[this.lang]"></span>
            </router-link>
        </div>
        <div class='col d-flex flex-column menu_col'>
            <router-link v-for="(link, index) in this.menu.middle" :id="link.to" v-show="(this.type == 'mobile' && link.mobile_visible !== false) || this.type != 'mobile' && link.desktop_visible !== false" :class='{"not": link.to == "#", "menu-header": link.type == "title"}, link.type' :key='index' :to="link.to">
                <div class="c34 d-flex align-items-center justify-content-center mr-05"><i class='item-i contain c34' :class='link.icon'></i></div>
                <h4 v-if="link.type == 'title'" v-on:click="(e) => {this.close(e, link)}" :class="{'m-h':this.type == 'mobile'}" v-html="link.content[this.$i18n.locale] ? link.content[this.$i18n.locale] : link.content[this.lang]"></h4>
                <span v-if="link.type == 'item'" v-on:click="(e) => {this.close(e, link)}" :class="{'m-s':this.type == 'mobile'}" v-html="link.content[this.$i18n.locale] ? link.content[this.$i18n.locale] : link.content[this.lang]"></span>
            </router-link>
        </div>
        <div class='col d-flex flex-column menu_col justify-content-between'>
            <div class="col d-flex flex-column">
            <router-link v-for="(link, index) in this.menu.last" :id="link.to" v-show="(this.type == 'mobile' && link.mobile_visible !== false) || this.type != 'mobile' && link.desktop_visible !== false" :class='{"not": link.to == "#", "menu-header": link.type == "title"}, link.type' :key='index' :to="link.to">
                <div class="c34 d-flex align-items-center justify-content-center mr-05"><i class='item-i contain c34' :class='link.icon'></i></div>
                <h4 v-if="link.type == 'title'" v-on:click="(e) => {this.close(e, link)}" :class="{'m-h':this.type == 'mobile'}" v-html="link.content[this.$i18n.locale] ? link.content[this.$i18n.locale] : link.content[this.lang]"></h4>
                <span v-if="link.type == 'item'" v-on:click="(e) => {this.close(e, link)}" :class="{'m-s':this.type == 'mobile'}" v-html="link.content[this.$i18n.locale] ? link.content[this.$i18n.locale] : link.content[this.lang]"></span>
            </router-link>
            </div>
            <div class="d-flex justify-content-end" v-if="this.menu.type == 'burger'">
                <LangSwitcher :type="'menu'"/>
            </div>
        </div>
    </div>
    <div v-else-if="this.type == 'mobile' && this.menu.type == 'center'" class="row align-items-start mb-0">
        <router-link :id="this.menu.to" :to="this.menu.to">
            <h4 v-on:click="(e) => {this.close(e, link)}" v-html="this.menu.content[this.$i18n.locale] ? this.menu.content[this.$i18n.locale] : this.menu.content[this.lang]" class='light color-acent m-title'></h4>
        </router-link>
    </div>
</template>

<script>
import LangSwitcher from '@/components/service/LangSwitcher';

export default {
    components: {
        LangSwitcher
    },
    props: {
        menu: [Object, Boolean],
        type: {
            type: [String, Boolean],
            default: false
        },
    },
    data() {
        return {
            lang: process.env.VUE_APP_I18N_LOCALE,
        }
    },
    created() {

    },
    mounted() {
        // console.log(this.menu);
        // console.log(process.env.VUE_APP_I18N_LOCALE)
    },
    methods: {
        close(event, link) {
            if (link.adv) {
                if (link.adv == 'external') {
                    event.preventDefault();
                    location.href = link.to;
                }
            }
            this.$emit('close', event);
        },
        handler(a) {
            console.log(a)
            location.herf = 'https://yandex.ru';
        }
    },
}
</script>

<style lang="scss" scoped>
.dropdown-m {
        .user_img {
            width: 50px;
            height:50px;
            min-width: 50px;
            min-height: 50px;
            margin-right: 0.5rem;
            border-radius: 50%;
        }
        .item-i {
            margin-right: 0.5rem;
        }
        .m-title {
            margin-bottom: 1rem !important;
        }
        .simple-a {
            font-size: 1rem;
        }
        .menu-header {
            padding-top: 20px;
        }
        .menu-header:first-child {
            padding-top: 0px;
        }
        .m-h {
            font-size: 1rem;
        }
        .m-s {
            font-size: 0.8rem;
        }
        .menu_col {
            min-width: 180px;
            max-width: 50%;
        }
        a {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            // cursor: pointer;
            font-family: 'HelveticaNeue roman';
            transition: all 0.2s;
            // color: red !important;
            p {
                font-family: 'HelveticaNeue light';
            }
            h4 {
                margin: 0;
                font-size: 1.35rem;
            }
        }
        a:hover {
            color: $color-acent;
        }
        .not {
            cursor:default !important;
        }
        .not:hover {
            color: #000 !important;
        }
        .title {
            margin-top: 0.6rem;
        }
        a:first-child {
            margin-top: 0 !important;
        }
    }
</style>