<template>
  <div class="header navbar bd-nawbar p-0">
    <!-- <div class="container-fluid p-0 color_menu" 
            :class="{'color_menu_gradient': process.env.VUE_APP_INDIVIDUAL == 'Crypto', 'color_menu': process.env.VUE_APP_INDIVIDUAL != 'Crypto'}"
        > -->
    <div class="container-fluid p-0 " :class="this.class_menu">
      <div
        v-if="this.$store.state.webViewStatusBar > 0"
        :style="`height: ${this.$store.state.webViewStatusBar}px; width: 100%;`"
      ></div>
      <div class="container-xl p-0">
        <div class="container-fluid flex-md-nowrap top-menu">
          <div class="logo">
            <router-link :to="'/'">
              <img
                v-if="this.$store.state.logo.img != 'logoDefault.svg'"
                :style="this.$store.state.logo.style"
                :src="this.$store.state.logo.img"
              />
              <img
                v-else
                :style="this.$store.state.logo.style"
                :src="require(`@/assets/${this.$store.state.logo.img}`)"
              />
            </router-link>
          </div>
          <div v-if="this.$store.state.resize.w > 860" class="nav">
            <DropMenu :menu="this.$store.state.menu.left" />
            <DropMenu :menu="this.$store.state.menu.center" />
            <DropMenu :menu="this.$store.state.menu.right" />
          </div>
          <div v-if="this.$store.state.resize.w > 860" class="nav">
            <DropMenu :menu="this.$store.state.menu.user" />
            <DropMenu :menu="this.$store.state.menu.burger" />
          </div>
          <div v-if="this.$store.state.resize.w < 860" class="nav">
            <MobileMenu :menu="this.$store.state.menu" />
          </div>
        </div>
      </div>
    </div>
    <transition @enter="enter" @leave="leave">
      <div
        class="container-fluid p-0 absh"
        :style="
          this.$store.state.resize.w > 860
            ? `top: ${75 + this.$store.state.webViewStatusBar}px;`
            : `top: ${50 + this.$store.state.webViewStatusBar}px;`
        "
        v-if="!this.scrolled"
      >
        <Keyts
          v-if="this.$store.state.userData.keyts"
          :key="this.$store.state.globalKeyKeyt"
        />
        <div
          v-if="this.$store.state.userData.antiphishing"
          ref="antiph"
          class="antiphishing"
        >
          <div class="container-xl p-0">
            <div class="container-fluid p-0 position-relative">
              <h5 class="antiphishing-text m-2">
                {{ this.$store.state.userData.antiphishing }}
              </h5>
              <i
                class="item-i cross"
                v-on:click="
                  () => {
                    this.$refs.antiph.remove();
                  }
                "
              ></i>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DropMenu from "@/components/service/topMenu/DropMenu.vue";
import MobileMenu from "@/components/service/topMenu/MobileMenu.vue";
import Keyts from "@/components/service/topMenu/Keyts.vue";
import { gsap } from "gsap";

export default {
  name: "HeadNav",
  components: {
    DropMenu,
    MobileMenu,
    Keyts,
  },
  data() {
    return {
      scrolled: false,
      class_menu: ''
    };
  },
  mounted() {
    console.log(process.env.VUE_APP_INDIVIDUAL)
    this.class_menu = process.env.VUE_APP_INDIVIDUAL == 'Crypto' ? "color_menu_gradient" : "color_menu"
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    });
  },
  methods: {
    logout() {
      this.axios.post("logout").then((res) => {
        console.log("logout");
        this.$store.commit("USER_DATA", false);
        this.$router.push("/login");
      });
    },
    enter(el, done) {
      gsap.fromTo(
        el,
        { opacity: 0.1, height: "10%" },
        { opacity: 1, height: `auto`, duration: 0.2, onComplete: done }
      );
    },
    leave(el, done) {
      gsap.fromTo(
        el,
        { opacity: 1, height: `auto` },
        { height: "10%", opacity: 0.1, duration: 0.2, onComplete: done }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  // box-shadow: 1px 2px 4px 1px rgb(1 52 23 / 10%);
  font-family: "HelveticaNeue light";
  z-index: 2001;
  flex-direction: column;
  position: sticky;
  top: 0;
  .color_menu {
    background-color: $color-menu;
  }
  .color_menu_gradient {
    background-image: linear-gradient(to right, $color-gradient-start 0%, $color-gradient-end 100%);
  }
  .absh {
    position: absolute;
  }
  .top-menu {
    height: 75px;
    position: relative;
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    .nav {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 100%;
      h4 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 4px solid transparent;
        box-sizing: padding-box;
        margin: 0 1.4rem;
        color: #fff;
      }
    }
    .service {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      height: 100%;
      img {
        width: 25px;
      }
    }
    @media (max-width: 860px) {
      height: 50px;
      .logo {
        img {
          height: 40px;
        }
      }
    }
  }
}
.antiphishing {
  background-color: $color-antiphishing;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  &-text {
    font-size: 1.1rem;
  }
  i {
    position: absolute;
    right: 5px;
    top: -5px;
    cursor: pointer;
  }
}
</style>
