<template>
    <li class="dropdown-t">
        <span ref="ddt" class="navbarDropdown" :class="{'link-active': this.active, bg: this.menu.type == 'burger'}" v-on:click="this.open">
            <div class="d-flex flex-row p-0 m-0">
                <span class="mr-1" v-if="this.$store.state.resize.w > 478 && this.menu.user">
                    <img v-if="this.$store.state.userData.avatar" class='user_img' :src="this.$store.state.userData.avatar" />
                    <img v-else class='user_img' src="@/assets/user.svg"/>
                    <span v-if="this.$store.getters.get_user_name != ''" class="user_name">
                        {{this.$store.getters.get_user_name}}
                    </span>
                </span>
                <span>
                    <i class='item-i' :class="{'burger-active': this.active, 'burger': !this.active}"></i>
                </span>
            </div>
        </span>
        <transition @enter="enter" @leave="leave">
        <div ref="ddm" class="container content dropdown-m p-4" v-show="this.active">
            <MenuList :class="{'mb-3': menu.type != 'center'}" v-for="(menu, index) in this.menu" :menu='menu' :type="'mobile'" :key="index" :id="index" @close='this.close'/>
            <!-- <div class="bp-2"></div> -->
        </div>
        </transition> 
    </li>
</template>

<script>
import { gsap } from "gsap";
import MenuList from '@/components/service/topMenu/MenuList'

export default {
    components: {
        MenuList
    },
    props: {
        menu: [Object, Boolean],
    },
    data() {
        return {
            active: false,
        }
    },
    mounted() {
        // console.log(this.menu)
    },
    methods: {
        open() {
            if (this.menu && this.active == false) {
                this.active = true;
                this.$store.commit('DARK_WRAPPER', true);
                document.querySelector('body').addEventListener('mouseover', this.close);
            } else if (this.menu && this.active == true) {
                this.active = false;
                this.$store.commit('DARK_WRAPPER', false);
                document.querySelector('body').removeEventListener('mouseover', this.close);
            }
        },
        close(e) {
            if (!this.$el.contains(e.target) || e.type == 'click') {
                this.active = false;
                this.$store.commit('DARK_WRAPPER', false);
                document.querySelector('body').removeEventListener('mouseover', this.close);
            }
        },
        enter(el) {
            gsap.fromTo(el, {opacity: 0.1, height: '95%'}, {opacity: 1, height: `${this.getHeight()}px;` , duration: 0.1});
        },
        leave(el, done) {
            gsap.fromTo(el, {opacity: 1}, {opacity: 0.1, duration: 0.2, onComplete: done});
        },
        logout() {
            this.$store.dispatch('logout', this.$router);
        },
        getHeight() {
            console.log(window.screen)
            return String(window.innerHeight - 75);
        },
    },
}
</script>

<style lang="scss" scoped>

.dropdown-t {
    height: 100%;
    .navbarDropdown {
        span {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            cursor: pointer;
        }
        .user_img {
            width: 35px;
            height: 35px;
            // margin-right: 0.5rem;
            border-radius: 50%;
        }
        .user_name {
            margin-left: 0.5rem;
        }
        .router-link-active {
            h4 {
                color: $color-menu-active;
            }
        }
        h4 {
            color: white;
        }
    }
    .dropdown-m {
        position: absolute;
        max-width: 100%;
        z-index: 3000;
        box-shadow: 2px 2px 4px 2px rgba(1, 52, 23, 0.1);
        border-radius: 0px 0px 6px 6px;
        left: 0;
        right: 0;
        overflow-y: auto;
    }
    .navbarDropdown {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 4px solid transparent;
        box-sizing: padding-box;
        margin: 0;
        color: #FFF;
    }
}
.link-active {
    border-bottom: 4px solid $color-menu-active !important;
    color: $color-menu-active !important;
}
.bg {
    margin-right: 0 !important;
}

</style>